<template>
  <modal
    class="newBookingPopup-container"
    name="NewBookingPopup"
    transition="pop-out"
    @opened="opened"
  >
    <div class="newBookingPopup" id="newBookingPopup">
      <div class="newBookingPopup__inner">
        <div class="newBookingPopup__title">Create new booking</div>
        <div class="newBookingPopup__form">
          <form
            class="form form--newBooking js-createBookingForm js-ajaxForm"
            :class="[{'round' : type === 'round-trip'}, {'loading' : loading}]"
            @submit.prevent="submitHandler"
            data-href="/json/test.json"
            data-action="newBooking"
            id="newBookingForm"
          >
            <label class="form__label form__label--text">
              <div class="form__caption">Aircraft Provider:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-typeahead"
                  type="text"
                  v-model="aircraftProvider"
                  placeholder="Enter here"
                  :disabled="loading"
                />
                <span
                  v-if="$v.aircraftProvider.$dirty && !$v.aircraftProvider.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </div>
            </label>
            <label class="form__label form__label--text">
              <div class="form__caption">Aircraft tail number:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-typeahead"
                  type="text"
                  v-model="aircraftTailNumber"
                  placeholder="Enter here"
                  :disabled="loading"
                />
                <span
                  v-if="$v.aircraftTailNumber.$dirty && !$v.aircraftTailNumber.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </div>
            </label>
            <label class="form__label form__label--number js-counter">
              <div class="form__caption">Pax No:</div>
              <div class="form__field-wrapper">
                <div class="form__icon">
                  <SvgIcon name="users"/>
                </div>
                <input
                  class="form__field js-counter-input"
                  type="number"
                  v-model="pax"
                  min="1"
                  max="99"
                  @blur="input"
                  :disabled="loading"
                />
                <div class="form__arrow">
                  <div class="form__arrow-plus js-counter-plus" @click="plus">
                    <SvgIcon name="up-arrow"/>
                  </div>
                  <div class="form__arrow-minus js-counter-minus" @click="minus">
                    <SvgIcon name="down-arrow"/>
                  </div>
                </div>
              </div>
            </label>
            <div class="form__labelGroup form__labelGroup--checkbox">
              <label class="form__label form__label--checkbox">
                <input
                  class="form__checkbox"
                  type="checkbox"
                  v-model="bookingType"
                  value="charter aircraft"
                  :disabled="loading"
                />
                <span class="form__checkbox-icon"></span>
                <span class="form__checkbox-caption">Book charter aircraft</span>
              </label>
              <label class="form__label form__label--checkbox">
                <input
                  class="form__checkbox"
                  type="checkbox"
                  v-model="bookingType"
                  value="charter seat"
                  :disabled="loading"
                />
                <span class="form__checkbox-icon"></span>
                <span class="form__checkbox-caption">Book charter seat</span>
              </label>
              <label class="form__label form__label--checkbox">
                <input
                  class="form__checkbox"
                  type="checkbox"
                  v-model="bookingType"
                  value="commercial seat"
                  :disabled="loading"
                />
                <span class="form__checkbox-icon"></span>
                <span class="form__checkbox-caption">Book commercial seat</span>
              </label>
            </div>
            <div class="form__labelGroup form__labelGroup--radio">
              <label class="form__label form__label--radio">
                <input
                  class="form__radio js-changeWay"
                  type="radio"
                  v-model="type"
                  value="one-way"
                  :disabled="loading"
                />
                <span class="form__radio-box">One-way</span>
                <span class="form__radio-icon" :style="{width: '12px'}">
                  <SvgIcon name="plane"/>
                </span>
              </label>
              <label class="form__label form__label--radio">
                <input
                  class="form__radio js-changeWay"
                  type="radio"
                  v-model="type"
                  value="round-trip"
                  :disabled="loading"
                />
                <span class="form__radio-box">Round-trip</span>
                <span class="form__radio-icon" :style="{width: '20px'}">
                  <SvgIcon name="double-plane"/>
                </span>
              </label>
            </div>
            <div class="form__tab js-tabBox">
              <div class="form__tab-nav">
                <div
                  class="form__tab-nav-item js-tabNavItem"
                  data-id="outbound"
                  @click="tabActivate"
                >
                  <span class="icon"><SvgIcon name="departures"/></span>
                  <span class="text">Outbound</span>
                </div>
                <div
                  class="form__tab-nav-item js-tabNavItem"
                  data-id="inbound"
                  @click="tabActivate"
                >
                  <span class="icon"><SvgIcon name="arrival"/></span>
                  <span class="text">Inbound</span>
                </div>
              </div>
              <div class="form__tab-content">
                <div class="form__tab-content-item js-tabContentItem" data-id="outbound">
                  <label class="form__label form__label--text">
                    <div class="form__caption">Departure FBO Location:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="outboundLocation"
                        placeholder="Type here"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.outboundLocation.$dirty && !$v.outboundLocation.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Departure airport:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-model="outboundDeparture"
                        placeholder="Take off"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.outboundDeparture.$dirty && !$v.outboundDeparture.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Arrival airport:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-model="outboundArrival"
                        placeholder="Land"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.outboundArrival.$dirty && !$v.outboundArrival.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--date">
                    <div class="form__caption">Dates and time:</div>
                    <div class="form__field-wrapper">
                      <a-range-picker
                        :show-time="{ format: 'HH:mm' }"
                        format="MM/DD/YYYY HH:mm"
                        :placeholder="['start date', 'end date']"
                        separator="-"
                        v-model="oneWayDates"
                        :getCalendarContainer="getPickerContainer"
                        :disabled="loading"
                      />
                      <div class="form__icon">
                        <SvgIcon name="calendar"/>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="form__tab-content-item js-tabContentItem" data-id="inbound">
                  <label class="form__label form__label--text">
                    <div class="form__caption">Departure FBO Location:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="inboundLocation"
                        placeholder="Type here"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.inboundLocation.$dirty && !$v.inboundLocation.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Departure airport:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-model="inboundDeparture"
                        placeholder="Take off"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.inboundDeparture.$dirty && !$v.inboundDeparture.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Arrival airport:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-model="inboundArrival"
                        placeholder="Land"
                        :disabled="loading"
                      />
                      <span
                        v-if="$v.inboundArrival.$dirty && !$v.inboundArrival.required"
                        class="form__field-invalid form__field-invalid--right-side"
                      >
                        This field is required
                      </span>
                    </div>
                  </label>
                  <label class="form__label form__label--date">
                    <div class="form__caption">Dates and time:</div>
                    <div class="form__field-wrapper">
                      <a-range-picker
                        :show-time="{ format: 'HH:mm' }"
                        format="MM/DD/YYYY HH:mm"
                        :placeholder="['start date', 'end date']"
                        separator="-"
                        v-model="roundTripDates"
                        :getCalendarContainer="getPickerContainer"
                        :disabled="loading"
                      />
                      <div class="form__icon">
                        <SvgIcon name="calendar"/>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <label class="form__label form__label--price price">
              <div class="form__caption">Booking Cost:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="cost"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.cost.$dirty && !$v.cost.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </div>
            </label>
            <div class="form__label form__label--button">
              <Spinner/>
              <button class="form__button" :disabled="loading">
                <span class="text">Complete</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('NewBookingPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import Spinner from '@/components/common/Spinner/Spinner'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Counter } from '@/js/plugins/counter'
  import { Tabs } from '@/js/plugins/tabs'
  import { required } from 'vuelidate/lib/validators'
  import { VMoney } from 'v-money'

  const counter = new Counter({
    min: 1,
    max: 99,
  })

  export default {
    name: 'NewBookingPopup',
    data: () => ({
      aircraftProvider: '',
      aircraftTailNumber: '',
      pax: 2,
      bookingType: ['charter aircraft'],
      type: 'one-way',
      outboundLocation: '',
      outboundDeparture: '',
      outboundArrival: '',
      oneWayDates: [],
      inboundLocation: '',
      inboundDeparture: '',
      inboundArrival: '',
      roundTripDates: [],
      cost: '',
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      loading: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: true,
      },
    }),
    directives: { money: VMoney },
    validations: {
      aircraftProvider: { required },
      aircraftTailNumber: { required },
      outboundLocation: { required },
      outboundDeparture: { required },
      outboundArrival: { required },
      inboundLocation: { required },
      inboundDeparture: { required },
      inboundArrival: { required },
      cost: { required },
    },
    methods: {
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
        this.$root.fields.changeRadio('.form__radio')
        this.$root.fields.changeChecked('.form__checkbox')
        this.tabs.addActiveForMouted()
      },
      minus () {
        this.pax = counter.callMinus(this.pax)
      },
      plus () {
        this.pax = counter.callPlus(this.pax)
      },
      input () {
        this.pax = counter.callInput(this.pax)
      },
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
      getPickerContainer (trigger) {
        return document.querySelector('.js-createBookingForm')
      },
      submitHandler () {
        if (this.type === 'one-way') {
          if (
            this.$v.aircraftProvider.$invalid ||
            this.$v.aircraftTailNumber.$invalid ||
            this.$v.outboundLocation.$invalid ||
            this.$v.outboundDeparture.$invalid ||
            this.$v.outboundArrival.$invalid ||
            this.$v.cost.$invalid
          ) {
            this.$v.$touch()
            return false
          }

          this.loading = true

          const data = {
            aircraftProvider: this.aircraftProvider,
            aircraftTailNumber: this.aircraftTailNumber,
            pax: this.pax,
            bookingType: this.bookingType,
            type: this.type,
            outboundLocation: this.outboundLocation,
            outboundDeparture: this.outboundDeparture,
            outboundArrival: this.outboundArrival,
            oneWayDates: this.oneWayDates,
            cost: this.cost,
          }

          console.log(data)
        } else {
          if (
            this.$v.aircraftProvider.$invalid ||
            this.$v.aircraftTailNumber.$invalid ||
            this.$v.outboundLocation.$invalid ||
            this.$v.outboundDeparture.$invalid ||
            this.$v.outboundArrival.$invalid ||
            this.$v.inboundLocation.$invalid ||
            this.$v.inboundDeparture.$invalid ||
            this.$v.inboundArrival.$invalid ||
            this.$v.cost.$invalid
          ) {
            this.$v.$touch()
            return false
          }

          this.loading = true

          const data = {
            aircraftProvider: this.aircraftProvider,
            aircraftTailNumber: this.aircraftTailNumber,
            pax: this.pax,
            bookingType: this.bookingType,
            type: this.type,
            outboundLocation: this.outboundLocation,
            outboundDeparture: this.outboundDeparture,
            outboundArrival: this.outboundArrival,
            oneWayDates: this.oneWayDates,
            inboundLocation: this.inboundLocation,
            inboundDeparture: this.inboundDeparture,
            inboundArrival: this.inboundArrival,
            roundTripDates: this.roundTripDates,
            cost: this.cost,
          }

          console.log(data)
        }
      },
    },
    components: {
      Spinner,
      SvgIcon,
    },
  }
</script>
