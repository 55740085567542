<template>
  <div
    class="requestsDashboard__table js-request-list"
    :class="'requestsDashboard__table--' + type"
  >
    <div v-if="!list.length" class="requestsDashboard__empty js-request-empty">no requests</div>
    <Pagination
      :pageCount="pageCount"
      :rowCount="requestsCount"
      @pageClick="onManualRequestsPageClick"
    />
    <div class="requestsDashboard__table-header js-request-header">
      <div class="requestsDashboard__table-cell">№</div>
      <div class="requestsDashboard__table-cell">Name</div>
      <div class="requestsDashboard__table-cell">Date/time</div>
      <div class="requestsDashboard__table-cell">Take off</div>
      <div class="requestsDashboard__table-cell">Land</div>
      <!-- <div class="requestsDashboard__table-cell">From</div> -->
      <!-- <div class="requestsDashboard__table-cell">To</div> -->
      <div class="requestsDashboard__table-cell">No.pax</div>
    </div>
    <div class="requestsDashboard__table-body js-customScroll">
      <vue-scroll :ops="ops">
        <div
          v-for="item of list"
          :key="item.id"
          class="requestsDashboard__table-row js-request"
        >
          <div class="requestsDashboard__table-info">
            <div class="requestsDashboard__table-cell">
              <div class="requestsDashboard__table-index">{{item.number}}</div>
            </div>
            <div class="requestsDashboard__table-cell">
              <a
              @click="showFlyerDetailsPopup(item.requester)"
              class="requestsDashboard__table-name" to="">{{item.fullName}}</a>
            </div>
            <div class="requestsDashboard__table-cell">{{moment(item.createdOn).format('M/D/YYYY')}}</div>
            <div class="requestsDashboard__table-cell requestsDashboard__table-cell--from">
              <div class="requestsDashboard__table-title">
                <span>{{item.departure}}</span>
                <span class="arrow"><SvgIcon name="right-arrow-2"/></span>
              </div>
            </div>
            <div class="requestsDashboard__table-cell requestsDashboard__table-cell--to">
              <div class="requestsDashboard__table-title">{{item.arrival}}</div>
            </div>
            <!-- <div class="requestsDashboard__table-cell requestsDashboard__table-cell--date-from">
              <span>{{item.from}}</span>
              <span class="requestsDashboard__table-separator">&thinsp;&#8208;&thinsp;</span>
            </div>
            <div class="requestsDashboard__table-cell requestsDashboard__table-cell--date-to">{{item.to}}</div> -->
            <div class="requestsDashboard__table-cell">
              <span class="requestsDashboard__table-caption">No.pax:</span>
              <span>{{item.pax}}</span>
            </div>
          </div>
          <router-link v-if="type === 'current'" to="" class="requestsDashboard__table-message">
            <SvgIcon name="mail-footer"/>
          </router-link>
          <div class="requestsDashboard__table-status">
            <div class="requestsDashboard__table-status-item">Request status: <b>{{item.statusValue}}</b></div>
            <span
              class="requestsDashboard__table-additional js-popup"
              @click="showAdditionalPopup(item.id, type)"
            >
              Additional info
            </span>
          </div>
        </div>
      </vue-scroll>
    </div>
    <FlyerDetailsPopup />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
  import Pagination from '@/components/common/Pagination/Pagination'
  import moment from 'moment'

  export default {
    props: ['type', 'list', 'pageCount', 'requestsCount', 'showAdditionalPopup'],
    name: 'RequestsDashboardTable',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    methods: {
      moment,
      showFlyerDetailsPopup (account) {
        this.$modal.show('FlyerDetailsPopup', {additionalInfo: account})
      },
      async onManualRequestsPageClick (data) {
        if (this.type === 'current') {
          await this.$store.dispatch('getManualRequestsList', data);
        } else {
          await this.$store.dispatch('getHistoricalManualRequestsList', data);
        }
      },
    },
    components: {
      SvgIcon,
      FlyerDetailsPopup,
      Pagination,
    },
  }
</script>
