export class Counter {
  constructor (elems) {
    this.min = elems.min
    this.max = elems.max
  }

  callMinus (value) {
    let count = parseInt(value) - 1
    count = count < this.min ? this.min : count
    value = count
    return value
  }

  callPlus (value) {
    let count = parseInt(value) + 1
    count = count < this.max ? count : this.max
    value = count
    return value
  }

  callInput (value) {
    const count = parseInt(value)
    if (count < this.min) {
      value = this.min
    } else if (count > this.max) {
      value = this.max
    } else if (!count) {
      value = this.min
    }
    return value
  }

  // minutes counter
  callMunutesMinus (value) {
    const count = parseInt(value) - 1
    if (count < this.min) {
      value = this.max
    } else if (count < 10 && count >= 0) {
      value = '0' + count
    } else {
      value = count
    }
    return value
  }

  callMunutesPlus (value) {
    const count = parseInt(value) + 1
    if (count > this.max) {
      value = '0' + this.min
    } else if (count < 10 || count === 0) {
      value = '0' + count
    } else {
      value = count
    }
    return value
  }

  callMunutesInput (value) {
    const count = parseInt(value)
    if (count < this.min) {
      value = this.min
    } else if (count > this.max) {
      value = this.max
    } else if (!count) {
      value = '0' + this.min
    }
    return value
  }
}
