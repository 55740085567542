<template>
  <modal name="FlyerDetailsPopup" transition="pop-out" @opened="opened" @before-open="beforeOpen">
    <div class="requestsDashboardDetail">
      <div
        class="requestsDashboardDetail__inner"
      >
<!--        <div class="requestsDashboardDetail__title">Additional info<br>for the manual flight request</div>-->
        <div class="requestsDashboardDetail__title">Member profile details</div>
        <div class="requestsDashboardDetail__info" v-if="additionalInfo">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">ID</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.id}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Number</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.number}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">First Name</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.firstName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Middle Name</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.middleName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Last Name</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.lastName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Date Of birth</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.dateOfBirth}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Email</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.email}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">mobile</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.mobile}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Address</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.address}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Created On</div>
            <div class="requestsDashboardDetail__info-item-value">{{moment(additionalInfo.createdOn).format('M/D/YYYY')}}</div>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('FlyerDetailsPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import moment from 'moment'
  import './RequestsDetailPopup.styl'

  export default {
    name: 'FlyerDetailsPopup',
    props: ['justInfo'],
    data: () => ({
      type: null,
      additionalInfo: null,
    }),
    methods: {
      moment,
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
      },
      beforeOpen (event) {
        this.type = event.params.type
        this.additionalInfo = event.params.additionalInfo
        // console.log(this.additionalInfo);
      },
      showConfirmation () {
        this.$modal.hide('FlyerDetailsPopup')
        this.$modal.show('questionPopupFlightRequest')
      },
      showBookingCreationForm () {
        this.$modal.hide('FlyerDetailsPopup')
        this.$modal.show('NewBookingPopup')
      },
    },
  }
</script>
