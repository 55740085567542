<template>
  <div
    class="requestsDashboard"
    :class="'requestsDashboard--' + dashboardType"
  >
    <vue-scroll :ops="ops" v-if="dashboardType === 'flyer'">
      <div class="requestsDashboard__header">
        <div v-if="dashboardType" class="requestsDashboard__title">FLYEmpty Requests</div>
      </div>
      <div class="requestsDashboard__content">
        <RequestsDashboardList :miniTab="false" :type="dashboardType"/>
      </div>
    </vue-scroll>

    <vue-scroll :ops="ops" v-else-if="dashboardType === 'provider'">
      <div class="requestsDashboard__header">
        <div v-if="dashboardType" class="requestsDashboard__title">
          <span class="text text--desktop">Requests</span>
          <span class="text text--mobile">Route alerts charters</span>
        </div>
        <div class="requestsDashboard__nav">
          <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="tabActivate">
            <span class="text text--desktop">My requests</span>
            <span class="text text--mobile">One way</span>
          </div>
          <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="tabActivate">
            <span class="text text--desktop">Flyer requests and status</span>
            <span class="text text--mobile">Round trip</span>
          </div>
        </div>
      </div>
      <div class="requestsDashboard__content">
        <div class="requestsDashboard__content-inner">
          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
              <RequestsDashboardList :miniTab="false" :type="dashboardType"/>
          </div>
          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
              <RequestsDashboardList :miniTab="false" :type="dashboardType"/>
          </div>
        </div>
      </div>
    </vue-scroll>

    <div v-else-if="dashboardType === 'admin'" class="requestsDashboard__admin-content">
      <div class="requestsDashboard__header">
        <div class="requestsDashboard__firstline">
          <div class="requestsDashboard__title">Flight requests by date and time</div>
          <div class="requestsDashboard__button" @click="exportToExcel()">
            <span
            class="text">Export</span>
          </div>
        </div>
        <div class="requestsDashboard__nav">
          <div class="requestsDashboard__navLeft">
            <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="(e) => {tabActivate(e)}">
              <span class="text">Current</span>
            </div>
            <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="(e) => {tabActivate(e)}">
              <span class="text">Historical</span>
            </div>
          </div>
        </div>
      </div>
      <div class="requestsDashboard__content">
        <div class="requestsDashboard__content-inner">
          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
            <div class="requestsDashboard__content-item-inner">
              <RequestsDashboardTable
              :type="'current'"
              :list="requestsListCurrent"
              :pageCount="manualRequestsPageCount('current')"
              :requestsCount="manualRequestsListCount('current')"
              :showAdditionalPopup="showAdditionalPopup"/>
            </div>
          </div>
          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
            <div class="requestsDashboard__content-item-inner">
              <RequestsDashboardTable
              :type="'historical'"
              :list="requestsListHistoric"
              :pageCount="manualRequestsPageCount('historical')"
              :requestsCount="manualRequestsListCount('historical')"
              :showAdditionalPopup="showAdditionalPopup"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RequestsDetailPopup :justInfo="true"/>
    <QuestionPopup
      :name="'questionPopupFlightRequest'"
      :title="'The request will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
    <NewBookingPopup/>
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import RequestsDashboardList from '@/components/dashboard/RequestsDashboardList/RequestsDashboardList'
  import RequestsDashboardTable from '@/components/dashboard/RequestsDashboardTable/RequestsDashboardTable'
  import { Tabs } from '@/js/plugins/tabs'
  import RequestsDetailPopup from '@/components/common/modals/RequestsDetailPopup'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import NewBookingPopup from '@/components/common/modals/NewBookingPopup'
  import './Requests.styl'

  export default {
    props: ['dashboardType'],
    name: 'Requests',
    data: () => ({
      tabs: new Tabs('.js-tabRequestsNavItem', '.js-tabRequestsContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      requestsListCurrent () {
        return this.$store.state.booking.manualRequests
      },
      requestsListHistoric () {
        return this.$store.state.booking.historicalManualRequests
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
      remove () {
        this.$store.commit('removeItemFlightRequest')
      },
      showAdditionalPopup (id, type) {
        const additionalInfo = type === 'current' ? this.$store.state.booking.manualRequests.find(item => item.id === id) : this.$store.state.booking.historicalManualRequests.find(item => item.id === id)
        this.$modal.show('RequestsDetailPopup', {additionalInfo: additionalInfo})
      },
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.flightReqs();

          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'flightReqs_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e);
        }
      },
      manualRequestsPageCount (type) {
        console.log(this.$store.state.booking.manualRequestsPageCount)
        if (type === 'current') {
          return this.$store.state.booking.manualRequestsPageCount
        } else {
          return this.$store.state.booking.historicalManualRequestsPageCount
        }
      },
      manualRequestsListCount (type) {
        if (type === 'current') {
          return this.$store.state.booking.manualRequestsListCount
        } else {
          return this.$store.state.booking.historicalManualRequestsListCount
        }
      },
    },
    components: {
      RequestsDashboardList,
      RequestsDashboardTable,
      RequestsDetailPopup,
      QuestionPopup,
      NewBookingPopup,
    },
  }
</script>
